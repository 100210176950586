import {
  LOADING_START,
  LOADING_END,
  SET_RESERVATIONS,
  SET_RESCHEDULED_RESERVATIONS,
  SET_INACTIVE_RESERVATIONS,
  SET_HOLIDAYS,
  UPDATE_RESERVATION,
  SET_SELECTED_RESERVATION,
  DELETE_RESERVATION,
  SET_CREATING_RESERVATION,
  ADD_RESERVATION,
  SET_CALENDAR_DATE,
  SET_IS_ADMIN,
  SET_CURRENT_PRODUCER_ID,
  SET_START_DATE,
  SET_END_DATE,
  SET_DRAGGING_RESERVATION,
  SET_PIVOT_DATE,
  SET_MOUSE_DOWN,
  SET_MOUSE_ON_DATE,
  SET_ARTISTS,
  SET_PRODUCERS,
  SET_RESERVATION_ERRORS,
  SHOW_MODAL,
  SET_MODAL_DATA,
  SHOW_FORM_MODAL,
  SET_CONFIRMATION_ATTEMPTS,
  SET_DUELS,
  SET_CURRENT_USER,
  SET_DUELS_MODAL_SHOWN,
  SET_TRANSFER_ATTEMPTS,
  SET_ARENA_TYPES,
  SET_CURRENT_RATE,
  SET_CURRENT_CANCEL_DATE_THRESHOLD,
} from '../../mutation-types';

export default {
  [SET_ARTISTS](state, artists) {
    state.artists = artists.reduce((acc, artist) => ({ ...acc, [artist.id]: artist }), {});
  },
  [SET_PRODUCERS](state, producers) {
    state.producers = [...producers];
  },
  [LOADING_START](state) {
    state.fetching = true;
  },
  [LOADING_END](state) {
    state.fetching = false;
  },
  [SET_RESERVATIONS](state, reservations) {
    state.reservations = reservations;
  },
  [SET_RESCHEDULED_RESERVATIONS](state, reservations) {
    state.rescheduledReservations = reservations;
  },
  [SET_INACTIVE_RESERVATIONS](state, reservations) {
    state.inactiveReservations = reservations;
  },
  [SET_HOLIDAYS](state, holidays) {
    state.holidays = holidays;
  },
  [UPDATE_RESERVATION](state, reservation) {
    state.reservations = [
      ...state.reservations.filter(res => res.id !== reservation.id),
      reservation,
    ];
  },
  [DELETE_RESERVATION](state, reservation) {
    state.reservations = [
      ...state.reservations.filter(res => res.id !== reservation.id),
    ];
  },
  [SET_SELECTED_RESERVATION](state, selectedReservationId) {
    state.selectedReservationId = selectedReservationId;
  },
  [SET_CREATING_RESERVATION](state, creating) {
    state.creatingReservation = creating;
  },
  [ADD_RESERVATION](state, reservation) {
    state.reservations = [
      ...state.reservations.filter(res => res.id !== reservation.id),
      reservation,
    ];
  },
  [SET_CALENDAR_DATE](state, date) {
    state.calendarDate = date;
  },
  [SET_IS_ADMIN](state, isAdmin) {
    state.isAdmin = isAdmin;
  },
  [SET_CURRENT_PRODUCER_ID](state, id) {
    state.currentProducerId = id;
  },
  [SET_START_DATE](state, date) {
    state.startDate = date;
  },
  [SET_END_DATE](state, date) {
    state.endDate = date;
  },
  [SET_PIVOT_DATE](state, date) {
    state.pivotDate = date;
  },
  [SET_DRAGGING_RESERVATION](state, dragging) {
    state.draggingReservation = dragging;
  },
  [SET_MOUSE_DOWN](state, mouseDown) {
    state.mouseDown = mouseDown;
  },
  [SET_MOUSE_ON_DATE](state, mouseOnDate) {
    state.mouseOnDate = mouseOnDate;
  },
  [SET_RESERVATION_ERRORS](state, errors) {
    state.errors = errors;
  },
  [SHOW_MODAL](state, value) {
    state.showModal = value;
  },
  [SHOW_FORM_MODAL](state, value) {
    state.showFormModal = value;
  },
  [SET_MODAL_DATA](state, modalData) {
    state.modalData = {
      header: modalData.header || '',
      body: modalData.body || '',
      footer: modalData.footer || '',
      button: modalData.button || '',
      action: modalData.action || {
        name: '',
        exec: null,
      },
    };
  },
  [SET_CONFIRMATION_ATTEMPTS](state, attempts) {
    state.confirmationAttempts = attempts;
  },
  [SET_DUELS](state, duels) {
    state.duels = duels;
  },
  [SET_CURRENT_USER](state, user) {
    state.currentUser = user;
  },
  [SET_DUELS_MODAL_SHOWN](state, shown) {
    state.duelsModalShown = shown;
  },
  [SET_TRANSFER_ATTEMPTS](state, attempts) {
    state.transferAttempts = attempts;
  },
  [SET_ARENA_TYPES](state, arenaTypes) {
    state.arenaTypes = arenaTypes;
  },
  [SET_CURRENT_RATE](state, rate) {
    state.currentRate = rate;
  },
  [SET_CURRENT_CANCEL_DATE_THRESHOLD](state, date) {
    state.currentCancelDateThreshold = date;
  }
};
