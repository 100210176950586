<template>
  <div
    class="reservation-detail"
    v-if="reservation"
  >
    <div
      class="reservation-detail__container sidebar"
      :class="[reservationColor]"
    >
      <div
        class="reservation-detail__close sidebar__close"
        @click="close()"
      >
        <div class="close-icon" />
      </div>
      <h1
        class="reservation-detail__title sidebar__title"
        v-if="reservation.eventName"
      >
        {{ reservation.eventName }}
      </h1>
      <h2 class="reservation-detail__subtitle sidebar__subtitle">
        {{ reservation.producerName }}
      </h2>
      <div class="reservation-detail__body sidebar__body">
        <div
          class="reservation-detail__info sidebar__info"
          v-if="reservation.id"
        >
          <div class="reservation-detail__label sidebar__label">
            <div class="barcode-icon" />
            Código de reserva:
          </div>
          <div class="reservation-detail__label sidebar__label">
            {{ reservation.id }}
          </div>
        </div>
        <div class="reservation-detail__info sidebar__info">
          <div class="reservation-detail__label sidebar__label">
            <div class="time-icon" />
            Fecha de inicio:
          </div>
          <div class="reservation-detail__label sidebar__label">
            {{ reservation.startDate | simpleDate }}
          </div>
        </div>
        <div class="reservation-detail__info sidebar__info">
          <div class="reservation-detail__label sidebar__label">
            <div class="time-icon" />
            Fecha de término:
          </div>
          <div class="reservation-detail__label sidebar__label">
            {{ reservation.endDate | simpleDate }}
          </div>
        </div>
        <div class="reservation-detail__info sidebar__info">
          <div class="reservation-detail__label sidebar__label">
            <div class="mounting-icon" />
            Días de montaje:
          </div>
          <div class="reservation-detail__label sidebar__label">
            {{ reservation.mountingDays }}
          </div>
        </div>
        <div class="reservation-detail__info sidebar__info">
          <div class="reservation-detail__label sidebar__label">
            <div class="dismounting-icon" />
            Días de desmontaje:
          </div>
          <div class="reservation-detail__label sidebar__label">
            {{ reservation.dismountingDays }}
          </div>
        </div>

        <div class="reservation-detail__info sidebar__info">
          <div class="reservation-detail__label sidebar__label">
            <div class="settings-icon" />
            Configuración:
          </div>
          <div class="reservation-detail__label sidebar__label">
            {{ this.arenaType && this.arenaType.name }}
          </div>
        </div>

        <div class="reservation-detail__info sidebar__info">
          <div class="reservation-detail__label sidebar__label">
            <div class="state-icon" />
            Estado:
          </div>
          <div
            class="reservation-detail__label sidebar__label"
            :class="[stateClass, this.reservation.state !== 'confirmed' ? queueClass : '']"
          >
            {{ RESERVATION_STATES[reservation.state] }}
          </div>
        </div>
        <div
          class="reservation-detail__info sidebar__info"
          v-if="isWaitingList"
        >
          <div class="reservation-detail__label sidebar__label">
            <div class="timer-icon" />
            Fecha expiración:
          </div>
          <div class="reservation-detail__label sidebar__label">
            {{ reservation.expiresAt | fullDateTime }}
          </div>
        </div>
        <div
          class="reservation-detail__info sidebar__info"
          v-if="isWaitingList"
        >
          <div class="reservation-detail__label sidebar__label">
            <div class="timer-icon" />
            Renovaciones restantes:
          </div>
          <div class="reservation-detail__label sidebar__label">
            {{ reservation.remainingRenewals }}
          </div>
        </div>
        <div
          class="reservation-detail__info sidebar__info"
          v-if="formattedRate && !reservation.confirmedRate"
        >
          <div class="reservation-detail__label sidebar__label">
            <div class="timer-icon" />
            Valor estimado:
          </div>
          <div class="reservation-detail__label sidebar__label">
            {{ formattedRate }}
          </div>
        </div>
        <div
          class="reservation-detail__info sidebar__info"
          v-if="reservation.confirmedRate"
        >
          <div class="reservation-detail__label sidebar__label">
            <div class="timer-icon" />
            Valor:
          </div>
          <div class="reservation-detail__label sidebar__label">
            {{ reservation.confirmedRate }}
          </div>
        </div>
        <div
          class="reservation-detail__info sidebar__info"
          v-if="reservation.duels.length > 0"
        >
          <div class="reservation-detail__label sidebar__label">
            <img
              class="icon-image"
              src="../../assets/images/swords.png"
            >
            Desafíos activos
          </div>
        </div>
        <div
          class="reservation-detail__info sidebar__info"
          v-if="attemptingToConfirm"
        >
          <div class="reservation-detail__label sidebar__label">
            <img
              class="icon-image"
              src="../../assets/images/star.png"
            >
            En proceso de confirmación. Expira {{ confirmationAttempt.expiresAt | calendarDate }}.
          </div>
        </div>
      </div>
      <reservation-actions
        class="reservation-detail__actions"
        :reservation="reservation"
        :rate="this.formattedRate"
        @reschedule-form="handleRescheduleForm()"
        @reassign-form="handleReassignForm()"
        @transfer-form="handleTransferForm()"
        @cancel-form="handleCancelForm()"
      />
      <div
        class="reservation-detail__body sidebar__body"
        v-if="showRescheduleForm"
      >
        <reschedule-form
          :reservation="reservation"
          @reschedule-form="handleRescheduleForm()"
        />
      </div>
      <div
        class="reservation-detail__body sidebar__body"
        v-if="showReassignForm"
      >
        <reassign-form
          :reservation="reservation"
          @reassign-form="handleReassignForm()"
        />
      </div>
      <div
        class="reservation-detail__body sidebar__body"
        v-if="showTransferForm"
      >
        <transfer-form
          :reservation="reservation"
          @transfer-form="handleTransferForm()"
        />
      </div>
      <div
        class="reservation-detail__body sidebar__body"
        v-if="showCancelForm"
      >
        <cancel-form
          :reservation="reservation"
          @cancel-form="handleCancelForm()"
        />
      </div>
    </div>
  </div>
</template>
<script>

import * as moment from 'moment-timezone';
import * as actions from '../store/action-types';
import * as mutations from '../store/mutation-types';
import ReservationActions from './reservation-actions';
import RescheduleForm from './reschedule-form';
import ReassignForm from './reassign-form';
import TransferForm from './transfer-form';
import CancelForm from './cancel-form';
import formatRate from '../filters/format-rate';

export default {
  name: 'ReservationDetail',
  components: {
    ReservationActions,
    RescheduleForm,
    ReassignForm,
    TransferForm,
    CancelForm,
  },
  props: {
    arenaTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      RESERVATION_STATES: {
        'waiting_list': 'Lista de espera',
        'confirmed': 'Confirmado',
        'zombie': 'Zombie',
        'in_confirmation_journey': 'Intentando confirmar',
        'in_reschedule_process': 'Intentando reagendar',
        'in_cancellation_journey': 'Intentando cancelar',
      },
      showRescheduleForm: false,
      showReassignForm: false,
      showTransferForm: false,
      showCancelForm: false,
    };
  },
  computed: {
    attemptingToConfirm() {
      return !!this.confirmationAttempt;
    },
    confirmationAttempt() {
      return this.$store.getters.confirmationAttempt(this.reservation);
    },
    arenaType() {
      return this.arenaTypes.find(type => type.id === this.reservation.arenaTypeId);
    },
    currentReservationId() {
      return this.$route.params.id;
    },
    reservation() {
      return this.$store.getters.selectedReservation();
    },
    isAdmin() {
      return this.$store.state.calendar.isAdmin;
    },
    currentProducerId() {
      return this.$store.state.calendar.currentProducerId;
    },
    isWaitingList() {
      return this.reservation.state === 'waiting_list';
    },
    stateClass() {
      return `reservation-state__${this.reservation.state}`;
    },
    queueClass() {
      if (this.reservation.canConfirm) {
        return 'reservation-state__can-confirm';
      } else if (this.reservation.state === 'confirmed') {
        return 'reservation-detail__confirmed';
      }

      return 'reservation-state__cannot-confirm';
    },
    reservationColor() {
      if (this.reservation.canConfirm) {
        return 'reservation-detail__can-confirm';
      } else if (this.reservation.state === 'confirmed') {
        return 'reservation-detail__confirmed';
      }

      return 'reservation-detail__cannot-confirm';
    },
    currentRate() {
      return this.$store.state.calendar.currentRate;
    },
    formattedRate() {
      return formatRate(
        this.currentRate,
        {
          mountingDays: this.reservation.mountingDays,
          dismountingDays: this.reservation.dismountingDays,
          startDate: this.reservation.startDate,
          endDate: this.reservation.endDate,
        },
      );
    },
  },
  watch: {
    reservation: {
      handler(val) {
        if (val) {
          this.$store.commit(mutations.SET_CURRENT_RATE, null);
          if (val.arenaTypeId) {
            this.$store.dispatch(actions.FETCH_RATE, { arenaTypeId: val.arenaTypeId, date: val.startDate });
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  filters: {
    simpleDate(unformattedDate) {
      const date = moment(unformattedDate);

      return `${date.date()} de ${date.format('MMMM')}`;
    },
    calendarDate(date) {
      return moment(date).calendar();
    },
    fullDateTime(date) {
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
  },
  methods: {
    close() {
      this.$store.dispatch(actions.UNSELECT_RESERVATION);
    },
    handleRescheduleForm() {
      this.showRescheduleForm = !this.showRescheduleForm;
    },
    handleReassignForm() {
      this.showReassignForm = !this.showReassignForm;
    },
    handleTransferForm() {
      this.showTransferForm = !this.showTransferForm;
    },
    handleCancelForm() {
      this.showCancelForm = !this.showCancelForm;
    },
  },
};
</script>
