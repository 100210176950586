<template>
  <div class="reservation-tag">
    <div
      class="calendar-cell-reservation__item"
      :class="{
        'reservation-tag--empty': isEmpty,
        'reservation-tag--is-first-for-date': isFirstForDate,
        'reservation-tag--confirmed': isConfirmed,
        'reservation-tag--in-cancellation-journey': reservation.state === 'in_cancellation_journey',
        'reservation-tag--not-first-for-date': notFirstForDate,
        'reservation-tag--mounting': isMountingDay,
        'reservation-tag--dismounting': isDismountingDay,
        'reservation-tag--highlight': shouldHighlight,
        'reservation-tag--private': !canSeeArtistOrFestival,
        'reservation-tag--reservation-start': isFirstReservationDay && !isLastReservationDay,
        'reservation-tag--reservation-end': isLastReservationDay && !isFirstReservationDay,
        'reservation-tag--reservation-middle': !isLastReservationDay && !isFirstReservationDay,
        'reservation-tag--reservation-single': isLastReservationDay && isFirstReservationDay,
      }"
    >
      <div
        class="reservation-tag__content"
        v-if="reservation"
      >
        <span
          class="reservation-tag__private-info"
          v-if="canSeePrivateData"
        >
          <span class="reservation_tag__data">
            {{ tagText }}
          </span>
        </span>
        <span
          class="reservation-tag__private-info"
          v-if="!canSeePrivateData && canSeeArtistOrFestival"
        >
          <span class="reservation_tag__data">
            {{ eventText }}
          </span>
        </span>
        <span
          class="reservation-tag__private-info"
          v-if="attemptingToConfirm"
        >
          <span class="reservation_tag__data">
            <img
              class="icon-image"
              src="../../assets/images/star.png"
            >
          </span>
        </span>
        <span
          class="reservation-tag__private-info"
          v-if="showConfirmationJourneyStar"
        >
          <span class="reservation_tag__data">
            <img
              class="icon-image"
              src="../../assets/images/star-white.png"
            >
          </span>
        </span>
        <span
          class="reservation-tag__private-info"
          v-if="isZombie"
        >
          <span class="reservation_tag__data">
            <img
              class="icon-image"
              src="../../assets/images/zombie.png"
            >
          </span>
        </span>
        <span
          class="reservation-tag__duel-icon"
          v-if="reservation.duels.length > 0 && !showConfirmationJourneyStar"
        >
          <img
            class="icon-image"
            src="../../assets/images/swords.png"
          >
        </span>
      </div>
      <div
        class="reservation-tag__content"
        v-else
      />
    </div>
  </div>
</template>
<script>

import * as moment from 'moment-timezone';
import reservationActions from './reservation-actions.vue';

const MAX_LENGTH_TAG_TEXT = 27;
const REPLACE_TEXT_SPACE = 3;

export default {
  name: 'ReservationTag',
  props: {
    reservation: {
      type: Object,
      default: () => {},
    },
    date: {
      type: Number,
      default: () => 0,
    },
    month: {
      type: Number,
      default: () => [],
    },
    firstPriority: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    showConfirmationJourneyStar() {
      return this.reservation.state === 'in_confirmation_journey' && !this.confirmationAttempt;
    },
    isZombie() {
      return this.reservation.state === 'zombie';
    },
    attemptingToConfirm() {
      return !!this.confirmationAttempt;
    },
    confirmationAttempt() {
      return this.$store.getters.confirmationAttempt(this.reservation);
    },
    isAdmin() {
      return this.$store.state.calendar.isAdmin;
    },
    eventText() {
      return this.reservation.eventName;
    },
    canSeeArtistOrFestival() {
      return this.reservation && this.reservation.eventName;
    },
    tagText() {
      let tagText = `${this.reservation.eventName}`;
      if (this.isAdmin) {
        tagText = tagText.concat(` - ${this.reservation.producerName}`);
      }

      if (tagText.length > MAX_LENGTH_TAG_TEXT) {
        tagText = tagText.substring(0, MAX_LENGTH_TAG_TEXT - REPLACE_TEXT_SPACE).concat('...');
      } else if (this.isMountingDay) {
        tagText = tagText.concat(' [M]');
      } else if (this.isDismountingDay) {
        tagText = tagText.concat(' [D]');
      }

      return tagText;
    },
    reservationDayNumber() {
      if (!this.reservation) {
        return 0;
      }
      const startDate = moment(this.reservation.startDate);

      if (this.month > startDate.month()) {
        return this.date + startDate.daysInMonth() - startDate.date() + 1;
      }

      return this.date - startDate.date() + 1;
    },
    reservationTotalDays() {
      if (!this.reservation) {
        return 0;
      }

      return moment(this.reservation.endDate).diff(moment(this.reservation.startDate), 'days') + 1;
    },
    isConfirmed() {
      return this.reservation && (this.reservation.state === 'confirmed' ||
        this.reservation.state === 'in_reschedule_process');
    },
    isEmpty() {
      return !this.reservation;
    },
    isFirstForDate() {
      return this.firstPriority;
    },
    notFirstForDate() {
      return this.reservation && !this.firstPriority;
    },
    isMountingDay() {
      return this.reservation && this.reservationDayNumber < 1;
    },
    isDismountingDay() {
      return this.reservation && this.reservationDayNumber > this.reservationTotalDays;
    },
    isFirstReservationDay() {
      if (!this.reservation) {
        return false;
      }
      const startDate = this.hasMountingDays ? this.reservation.startMountingDate : this.reservation.startDate;

      return moment(startDate).date() === this.date;
    },
    isLastReservationDay() {
      if (!this.reservation) {
        return false;
      }
      const endDate = this.hasDismountingDays ? this.reservation.endDismountingDate : this.reservation.endDate;

      return moment(endDate).date() === this.date;
    },
    canSeePrivateData() {
      return this.reservation && this.reservation.eventName && this.reservation.producerName && this.isAdmin;
    },
    hasMountingDays() {
      return this.reservation && this.reservation.mountingDays > 0;
    },
    hasDismountingDays() {
      return this.reservation && this.reservation.dismountingDays > 0;
    },
    shouldHighlight() {
      return this.canSeePrivateData && !this.isAdmin;
    },
  },
};
</script>
