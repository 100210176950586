export const INIT = 'INIT';

export const FETCH_RESERVATIONS = 'FETCH_RESERVATIONS';
export const FETCH_RESERVATIONS_FAILURE = 'FETCH_RESERVATIONS_FAILURE';
export const FETCH_RESERVATIONS_SUCCESSFUL = 'FETCH_RESERVATIONS_SUCCESSFUL';

export const FETCH_RESCHEDULED_RESERVATIONS = 'FETCH_RESCHEDULED_RESERVATIONS';
export const FETCH_RESCHEDULED_RESERVATIONS_FAILURE = 'FETCH_RESCHEDULED_RESERVATIONS_FAILURE';
export const FETCH_RESCHEDULED_RESERVATIONS_SUCCESSFUL = 'FETCH_RESCHEDULED_RESERVATIONS_SUCCESSFUL';

export const FETCH_INACTIVE_RESERVATIONS = 'FETCH_INACTIVE_RESERVATIONS';
export const FETCH_INACTIVE_RESERVATIONS_FAILURE = 'FETCH_INACTIVE_RESERVATIONS_FAILURE';
export const FETCH_INACTIVE_RESERVATIONS_SUCCESSFUL = 'FETCH_INACTIVE_RESERVATIONS_SUCCESSFUL';

export const FETCH_HOLIDAYS = 'FETCH_HOLIDAYS';
export const FETCH_HOLIDAYS_FAILURE = 'FETCH_HOLIDAYS_FAILURE';
export const FETCH_HOLIDAYS_SUCCESSFUL = 'FETCH_HOLIDAYS_SUCCESSFUL';

export const FETCH_DUELS = 'FETCH_DUELS';
export const FETCH_DUELS_FAILURE = 'FETCH_DUELS_FAILURE';
export const FETCH_DUELS_SUCCESSFUL = 'FETCH_DUELS_SUCCESSFUL';

export const FETCH_CONFIRMATION_ATTEMPTS = 'FETCH_CONFIRMATION_ATTEMPTS';
export const FETCH_CONFIRMATION_ATTEMPTS_FAILURE = 'FETCH_CONFIRMATION_ATTEMPTS_FAILURE';
export const FETCH_CONFIRMATION_ATTEMPTS_SUCCESSFUL = 'FETCH_CONFIRMATION_ATTEMPTS_SUCCESSFUL';

export const START_CONFIRMATION_JOURNEY = 'START_CONFIRMATION_JOURNEY';
export const START_CONFIRMATION_JOURNEY_FAILURE = 'START_CONFIRMATION_JOURNEY_FAILURE';
export const START_CONFIRMATION_JOURNEY_SUCCESSFUL = 'START_CONFIRMATION_JOURNEY_SUCCESSFUL';

export const RESCHEDULE_RESERVATION = 'RESCHEDULE_RESERVATION';
export const RESCHEDULE_RESERVATION_FAILURE = 'RESCHEDULE_RESERVATION_FAILURE';
export const RESCHEDULE_RESERVATION_SUCCESSFUL = 'RESCHEDULE_RESERVATION_SUCCESSFUL';

export const REMOVE_RESERVATION = 'REMOVE_RESERVATION';
export const REMOVE_RESERVATION_FAILURE = 'REMOVE_RESERVATION_FAILURE';
export const REMOVE_RESERVATION_SUCCESSFUL = 'REMOVE_RESERVATION_SUCCESSFUL';

export const RENEW_RESERVATION = 'RENEW_RESERVATION';
export const RENEW_RESERVATION_FAILURE = 'RENEW_RESERVATION_FAILURE';
export const RENEW_RESERVATION_SUCCESSFUL = 'RENEW_RESERVATION_SUCCESSFUL';

export const SELECT_RESERVATION = 'SELECT_RESERVATION';
export const UNSELECT_RESERVATION = 'UNSELECT_RESERVATION';

export const SHOW_INACTIVE_RESERVATIONS = 'SHOW_INACTIVE_RESERVATIONS';
export const CLOSE_INACTIVE_RESERVATIONS = 'CLOSE_INACTIVE_RESERVATIONS';

export const START_CREATING_RESERVATION = 'START_CREATING_RESERVATION';
export const STOP_CREATING_RESERVATION = 'STOP_CREATING_RESERVATION';

export const CREATE_RESERVATION = 'CREATE_RESERVATION';
export const CREATE_RESERVATION_SUCCESSFUL = 'CREATE_RESERVATION_SUCCESSFUL';
export const CREATE_RESERVATION_FAILURE = 'CREATE_RESERVATION_FAILURE';

export const CHANGE_MONTH = 'CHANGE_MONTH';

export const RESERVATION_EVENT_RECEIVED = 'RESERVATION_EVENT_RECEIVED';

export const SELECT_CURRENT_PRODUCER = 'SELECT_CURRENT_PRODUCER';
export const UNSELECT_CURRENT_PRODUCER = 'UNSELECT_CURRENT_PRODUCER';

export const MOUSE_ENTER_DATE = 'MOUSE_ENTER_DATE';
export const MOUSE_EXIT_DATE = 'MOUSE_EXIT_DATE';
export const MOUSE_DOWN_DATE = 'MOUSE_DOWN_DATE';
export const MOUSE_UP_DATE = 'MOUSE_UP_DATE';
export const MOUSE_MOVE_DATE = 'MOUSE_MOVE_DATE';
export const REMOVE_RESERVATION_ERRORS = 'REMOVE_RESERVATION_ERRORS';
export const ADD_RESERVATION_ERROR = 'ADD_RESERVATION_ERROR';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const CREATE_ARTIST = 'CREATE_ARTIST';
export const CREATE_ARTIST_SUCCESSFUL = 'CREATE_ARTIST_SUCCESSFUL';
export const CREATE_ARTIST_FAILURE = 'CREATE_ARTIST_FAILURE';

export const RESET_SELECTED_DATES = 'RESET_SELECTED_DATES';

export const GET_ARTISTS = 'GET_ARTISTS';
export const GET_ARTISTS_FAILURE = 'GET_ARTISTS_FAILURE';
export const GET_ARTISTS_SUCCCESSFUL = 'GET_ARTISTS_SUCCCESSFUL';

export const GET_PRODUCERS = 'GET_PRODUCERS';
export const GET_PRODUCERS_FAILURE = 'GET_PRODUCERS_FAILURE';
export const GET_PRODUCERS_SUCCESSFUL = 'GET_PRODUCERS_SUCCESSFUL';

export const GET_PRODUCER_PERMISSIONS = 'GET_PRODUCER_PERMISSIONS';
export const GET_PRODUCER_PERMISSIONS_SUCCESSFUL = 'GET_PRODUCER_PERMISSIONS_SUCCESSFUL';
export const GET_PRODUCER_PERMISSIONS_FAILURE = 'GET_PRODUCER_PERMISSIONS_FAILURE';

export const REDIRECT_TO_RESERVATION = 'REDIRECT_TO_RESERVATION';

export const FIND_RESERVATION = 'FIND_RESERVATION';
export const FIND_RESERVATION_FAILURE = 'FIND_RESERVATION_FAILURE';
export const FIND_RESERVATION_SUCCESSFUL = 'FIND_RESERVATION_SUCCESSFUL';

export const TOGGLE_PENDINGS = 'TOGGLE_PENDINGS';

export const MARK_RESERVATION_AS_ZOMBIE = 'MARK_RESERVATION_AS_ZOMBIE';
export const MARK_RESERVATION_AS_ZOMBIE_SUCCESSFUL = 'MARK_RESERVATION_AS_ZOMBIE_SUCCESSFUL';
export const MARK_RESERVATION_AS_ZOMBIE_FAILURE = 'MARK_RESERVATION_AS_ZOMBIE_FAILURE';

export const TRANSFER_RESERVATION = 'TRANSFER_RESERVATION';
export const TRANSFER_RESERVATION_FAILURE = 'TRANSFER_RESERVATION_FAILURE';
export const TRANSFER_RESERVATION_SUCCESSFUL = 'TRANSFER_RESERVATION_SUCCESSFUL';

export const FETCH_TRANSFER_ATTEMPTS = 'FETCH_TRANSFER_ATTEMPTS';
export const FETCH_TRANSFER_ATTEMPTS_FAILURE = 'FETCH_TRANSFER_ATTEMPTS_FAILURE';
export const FETCH_TRANSFER_ATTEMPTS_SUCCESSFUL = 'FETCH_TRANSFER_ATTEMPTS_SUCCESSFUL';
export const REDIRECT_TO_TRANSFER_ATTEMPT = 'REDIRECT_TO_TRANSFER_ATTEMPT';
export const REDIRECT_TO_ROOT = 'REDIRECT_TO_ROOT';
export const UPDATE_TRANSFER_ATTEMPT = 'UPDATE_TRANSFER_ATTEMPT';
export const UPDATE_TRANSFER_ATTEMPT_FAILURE = 'UPDATE_TRANSFER_ATTEMPT_FAILURE';
export const UPDATE_TRANSFER_ATTEMPT_SUCCESSFUL = 'UPDATE_TRANSFER_ATTEMPT_SUCCESSFUL';

export const FETCH_RATE = 'FETCH_RATE';

export const CANCEL_RESERVATION = 'CANCEL_RESERVATION';
export const CANCEL_RESERVATION_FAILURE = 'CANCEL_RESERVATION_FAILURE';
export const CANCEL_RESERVATION_SUCCESSFUL = 'CANCEL_RESERVATION_SUCCESSFUL';
export const FETCH_CURRENT_CANCEL_DATE_THRESHOLD = 'FETCH_CURRENT_CANCEL_DATE_THRESHOLD';
export const FETCH_CURRENT_CANCEL_DATE_THRESHOLD_FAILURE = 'FETCH_CURRENT_CANCEL_DATE_THRESHOLD_FAILURE';