import * as humps from 'humps';
import api from './api';

export default {
  create(params) {
    const path = '/api/v1/cancel_attempts';
    const formData = new FormData();
    Object.keys(params).forEach(key => formData.append(humps.decamelize(key), params[key]));

    return api({
      method: 'post',
      data: formData,
      url: path,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  dateThreshold(reservationId) {
    const path = '/api/v1/cancel_attempts/date_threshold?reservation_id=' + reservationId;
    return api({
      method: 'get',
      url: path,
    });
  }
};
