<template>
  <div>
    <h1 class="sidebar__subform-title">
      Cancelar la fecha
    </h1>
    <div class="sidebar__body">
      <div class="sidebar__info sidebar__info--form field">
        <div class="sidebar__label">
          Tipo de Cancelación
        </div>
        <select
          id="category"
          v-model="category"
          class="input input--wide"
        >
          <option value="general">
            General
          </option>
          <option value="force_majeure">
            Evento Fortuito o Fuerza Mayor
          </option>
        </select>
      </div>
      <div class="sidebar__info sidebar__info--form field">
        <div class="sidebar__label">
          Motivo
        </div>
        <textarea
          id="reason"
          v-model="reason"
          class="input input--wide"
        />
      </div>
      <div class="sidebar__info sidebar__info--form field">
        <div class="sidebar__label">
          Cargar Documento
        </div>
        <input
          id="document"
          name="document"
          type="file"
          class="input input--wide"
          @change="changeFile"
        >
      </div>
    </div>
    <div
      v-if="errors.length > 0"
      class="sidebar__errors"
    >
      <div
        class="sidebar__error field-error"
        v-for="error in errors"
        :key="error"
      >
        {{ error }}
      </div>
    </div>
    <div class="reservation-form__actions sidebar__actions">
      <button
        v-if="formCompleted"
        class="sidebar__action"
        @click="confirmCancelReservation()"
      >
        Confirmar
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import * as actions from '../store/action-types';

export default {
  name: 'CancelForm',
  props: {
    reservation: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      reason: '',
      category: '',
      document: null,
    };
  },
  computed: {
    errors() {
      return this.$store.state.calendar.errors;
    },
    formCompleted() {
      return this.reason.length > 0 && this.document !== null && this.category.length > 0;
    },
    currentCancelDateThreshold() {
      return this.$store.state.calendar.currentCancelDateThreshold;
    },
    isBeforeCancelDateThreshold() {
      return moment().isBefore(this.currentCancelDateThreshold);
    }
  },
  mounted() {
    this.$store.dispatch(actions.FETCH_CURRENT_CANCEL_DATE_THRESHOLD, this.reservation.id);
  },
  methods: {
    changeFile(e) {
      this.document = e.target.files[0];
    },
    confirmCancelReservation() {
      this.$store.dispatch(
        actions.OPEN_MODAL,
        this.confirmCancelReservationDataInfo(),
      );
    },
    confirmCancelReservationDataInfo() {
      const body = this.isBeforeCancelDateThreshold && this.category === 'general'
        ? '¿Estás seguro de realizar esta acción?'
        : '¿Estás seguro de realizar esta acción? De no arrendarse el espacio se cobrará el costo oportunidad de la reserva, equivalente al arriendo.';
      return {
        header: 'Estás a punto de cancelar esta reserva ya confirmada',
        body,
        button: 'Volver',
        action: {
          name: 'Sí, cancelar',
          exec: this.cancelReservation,
        },
      };
    },
    cancelReservation() {
      this.$store.dispatch(
        actions.CANCEL_RESERVATION,
        {
          reservationId: this.reservation.id,
          category: this.category,
          reason: this.reason,
          document: this.document,
        },
      );
      this.$store.dispatch(actions.CLOSE_MODAL);
      this.$emit('cancel-form');
    },
  },
};
</script>
