import * as moment from 'moment-timezone';
import goToPath from '../../../utils/goToPath';

import {
  LOADING_START,
  LOADING_END,
  SET_RESERVATIONS,
  SET_RESCHEDULED_RESERVATIONS,
  SET_INACTIVE_RESERVATIONS,
  SET_HOLIDAYS,
  SET_SELECTED_RESERVATION,
  SET_CREATING_RESERVATION,
  ADD_RESERVATION,
  SET_CALENDAR_DATE,
  SET_CURRENT_PRODUCER_ID,
  SET_RESERVATION_ERRORS,
  SET_IS_ADMIN,
  SET_START_DATE,
  SET_END_DATE,
  SET_DRAGGING_RESERVATION,
  SET_PIVOT_DATE,
  SET_MOUSE_DOWN,
  SET_MOUSE_ON_DATE,
  SET_PRODUCERS,
  SET_ARTISTS,
  SHOW_MODAL,
  SHOW_FORM_MODAL,
  SET_MODAL_DATA,
  SET_CONFIRMATION_ATTEMPTS,
  SET_DUELS,
  SET_CURRENT_USER,
  SET_DUELS_MODAL_SHOWN,
  SET_TRANSFER_ATTEMPTS,
  SET_ARENA_TYPES,
  SET_CURRENT_RATE,
  SET_CURRENT_CANCEL_DATE_THRESHOLD,
} from '../../mutation-types';

import {
  FETCH_RESERVATIONS,
  FETCH_RESERVATIONS_FAILURE,
  FETCH_RESERVATIONS_SUCCESSFUL,
  FETCH_RESCHEDULED_RESERVATIONS,
  FETCH_RESCHEDULED_RESERVATIONS_FAILURE,
  FETCH_RESCHEDULED_RESERVATIONS_SUCCESSFUL,
  FETCH_INACTIVE_RESERVATIONS,
  FETCH_INACTIVE_RESERVATIONS_FAILURE,
  FETCH_INACTIVE_RESERVATIONS_SUCCESSFUL,
  FETCH_HOLIDAYS,
  FETCH_HOLIDAYS_FAILURE,
  FETCH_HOLIDAYS_SUCCESSFUL,
  START_CONFIRMATION_JOURNEY,
  START_CONFIRMATION_JOURNEY_FAILURE,
  START_CONFIRMATION_JOURNEY_SUCCESSFUL,
  RESCHEDULE_RESERVATION,
  RESCHEDULE_RESERVATION_FAILURE,
  RESCHEDULE_RESERVATION_SUCCESSFUL,
  REMOVE_RESERVATION,
  REMOVE_RESERVATION_FAILURE,
  REMOVE_RESERVATION_SUCCESSFUL,
  SELECT_RESERVATION,
  SHOW_INACTIVE_RESERVATIONS,
  CLOSE_INACTIVE_RESERVATIONS,
  UNSELECT_RESERVATION,
  START_CREATING_RESERVATION,
  STOP_CREATING_RESERVATION,
  CREATE_RESERVATION,
  CREATE_RESERVATION_SUCCESSFUL,
  CREATE_RESERVATION_FAILURE,
  CHANGE_MONTH,
  RESERVATION_EVENT_RECEIVED,
  SELECT_CURRENT_PRODUCER,
  UNSELECT_CURRENT_PRODUCER,
  MOUSE_ENTER_DATE,
  MOUSE_DOWN_DATE,
  MOUSE_UP_DATE,
  MOUSE_MOVE_DATE,
  INIT,
  REMOVE_RESERVATION_ERRORS,
  ADD_RESERVATION_ERROR,
  CLOSE_MODAL,
  OPEN_MODAL,
  CREATE_ARTIST,
  CREATE_ARTIST_SUCCESSFUL,
  CREATE_ARTIST_FAILURE,
  RESET_SELECTED_DATES,
  GET_ARTISTS,
  GET_ARTISTS_FAILURE,
  GET_ARTISTS_SUCCCESSFUL,
  GET_PRODUCERS,
  GET_PRODUCERS_SUCCESSFUL,
  GET_PRODUCERS_FAILURE,
  GET_PRODUCER_PERMISSIONS,
  GET_PRODUCER_PERMISSIONS_SUCCESSFUL,
  GET_PRODUCER_PERMISSIONS_FAILURE,
  RENEW_RESERVATION,
  RENEW_RESERVATION_FAILURE,
  RENEW_RESERVATION_SUCCESSFUL,
  REDIRECT_TO_RESERVATION,
  FIND_RESERVATION,
  FIND_RESERVATION_FAILURE,
  FIND_RESERVATION_SUCCESSFUL,
  FETCH_CONFIRMATION_ATTEMPTS,
  FETCH_CONFIRMATION_ATTEMPTS_SUCCESSFUL,
  FETCH_CONFIRMATION_ATTEMPTS_FAILURE,
  FETCH_DUELS,
  FETCH_DUELS_SUCCESSFUL,
  FETCH_DUELS_FAILURE,
  TOGGLE_PENDINGS,
  MARK_RESERVATION_AS_ZOMBIE,
  MARK_RESERVATION_AS_ZOMBIE_SUCCESSFUL,
  MARK_RESERVATION_AS_ZOMBIE_FAILURE,
  TRANSFER_RESERVATION,
  TRANSFER_RESERVATION_SUCCESSFUL,
  TRANSFER_RESERVATION_FAILURE,
  FETCH_TRANSFER_ATTEMPTS,
  FETCH_TRANSFER_ATTEMPTS_SUCCESSFUL,
  FETCH_TRANSFER_ATTEMPTS_FAILURE,
  REDIRECT_TO_TRANSFER_ATTEMPT,
  REDIRECT_TO_ROOT,
  UPDATE_TRANSFER_ATTEMPT,
  UPDATE_TRANSFER_ATTEMPT_FAILURE,
  UPDATE_TRANSFER_ATTEMPT_SUCCESSFUL,
  FETCH_RATE,
  CANCEL_RESERVATION,
  CANCEL_RESERVATION_SUCCESSFUL,
  CANCEL_RESERVATION_FAILURE,
  FETCH_CURRENT_CANCEL_DATE_THRESHOLD,
  FETCH_CURRENT_CANCEL_DATE_THRESHOLD_FAILURE
} from '../../action-types';

import sentence from '../../../utils/sentence';

import reservationApi from '../../../api/reservations';
import artistApi from '../../../api/artists';
import producersApi from '../../../api/producers';
import confirmationAttemptsApi from '../../../api/confirmation-attempts';
import rescheduleAttemptsApi from '../../../api/reschedule-attempts';
import transferAttemptsApi from '../../../api/transfer-attempts';
import cancelAttemptsApi from '../../../api/cancel-attempts';
import duelsApi from '../../../api/confirmation-duels';
import holidaysApi from '../../../api/holidays';
import rates from '../../../api/rates';

const MAX_RESERVATION_DURATION = 7;

function reservationsOfCurrentMonth(newReservations, currentDate) {
  if (newReservations.length === 0) {
    return false;
  }

  const startReservationMonth = moment(newReservations[0].startMountingDate).month();
  const endReservationMonth = moment(newReservations[0].endDismountingDate).month();
  const calendarMonth = moment(currentDate).month();

  return [startReservationMonth, endReservationMonth].includes(calendarMonth);
}

export default {
  [INIT]({ dispatch, commit }, { currentUser, arenaTypes }) {
    commit(SET_CURRENT_USER, currentUser);
    commit(SET_ARENA_TYPES, arenaTypes);
    dispatch(GET_ARTISTS);
    dispatch(GET_PRODUCERS);
    dispatch(GET_PRODUCER_PERMISSIONS);
    dispatch(FETCH_DUELS);
    dispatch(FETCH_RESCHEDULED_RESERVATIONS);
  },
  [CHANGE_MONTH]({ commit, dispatch }, { date }) {
    commit(SET_CALENDAR_DATE, moment(date).startOf('month').toDate());
    dispatch(UNSELECT_RESERVATION);
    dispatch(RESET_SELECTED_DATES);
    commit(SET_RESERVATIONS, []);
    commit(SET_INACTIVE_RESERVATIONS, []);
    commit(SET_HOLIDAYS, []);
    dispatch(FETCH_RESERVATIONS);
    dispatch(FETCH_INACTIVE_RESERVATIONS);
    dispatch(FETCH_CONFIRMATION_ATTEMPTS);
    dispatch(FETCH_HOLIDAYS);
  },
  [FETCH_HOLIDAYS]({ state, dispatch }) {
    holidaysApi.get(state.calendarDate)
      .then(holidays => dispatch(FETCH_HOLIDAYS_SUCCESSFUL, holidays))
      .catch(err => dispatch(FETCH_HOLIDAYS_FAILURE, err));
  },
  [FETCH_RESERVATIONS]({ state, commit, dispatch }) {
    commit(LOADING_START);
    reservationApi.getReservations(state.calendarDate)
      .then(reservations => dispatch(FETCH_RESERVATIONS_SUCCESSFUL, reservations))
      .catch(err => dispatch(FETCH_RESERVATIONS_FAILURE, err));
  },
  [FETCH_RESCHEDULED_RESERVATIONS]({ commit, dispatch }) {
    commit(LOADING_START);
    reservationApi.getRescheduledReservations()
      .then(rescheduledReservations => dispatch(FETCH_RESCHEDULED_RESERVATIONS_SUCCESSFUL, rescheduledReservations))
      .catch(err => dispatch(FETCH_RESCHEDULED_RESERVATIONS_FAILURE, err));
  },
  [FETCH_INACTIVE_RESERVATIONS]({ state, commit, dispatch }) {
    commit(LOADING_START);
    const id = state.currentProducerId ? state.currentProducerId : 0;
    producersApi.getInactiveReservations(id, state.calendarDate)
      .then(reservations => dispatch(FETCH_INACTIVE_RESERVATIONS_SUCCESSFUL, reservations))
      .catch(err => dispatch(FETCH_INACTIVE_RESERVATIONS_FAILURE, err));
  },
  [FETCH_HOLIDAYS_SUCCESSFUL]({ commit }, holidays) {
    commit(LOADING_END);
    commit(SET_HOLIDAYS, holidays);
  },
  [FETCH_RESERVATIONS_SUCCESSFUL]({ state, commit, rootState, dispatch }, reservations) {
    commit(LOADING_END);
    if (reservationsOfCurrentMonth(reservations, state.calendarDate)) {
      commit(SET_RESERVATIONS, reservations);
      const id = rootState.route.params.id;

      if (id && id !== 'new') {
        dispatch(REDIRECT_TO_RESERVATION, id);
      }
    } else {
      commit(SET_RESERVATIONS, []);
    }
  },
  [FETCH_RESCHEDULED_RESERVATIONS_SUCCESSFUL]({ commit }, reservations) {
    commit(LOADING_END);
    commit(SET_RESCHEDULED_RESERVATIONS, reservations);
  },
  [FETCH_INACTIVE_RESERVATIONS_SUCCESSFUL]({ state, commit }, reservations) {
    commit(LOADING_END);
    if (reservationsOfCurrentMonth(reservations, state.calendarDate)) {
      commit(SET_INACTIVE_RESERVATIONS, reservations);
    }
  },
  [REDIRECT_TO_RESERVATION]({ state, dispatch }, id) {
    if (state.reservations.find(res => res.id === id)) {
      dispatch(SELECT_RESERVATION, id);
    } else {
      dispatch(FIND_RESERVATION, id);
    }
  },
  [FIND_RESERVATION]({ dispatch, commit }, id) {
    commit(LOADING_START);
    reservationApi.getReservation(id)
      .then((reservation) => {
        dispatch(FIND_RESERVATION_SUCCESSFUL, reservation);
      })
      .catch((err) => dispatch(FIND_RESERVATION_FAILURE));
  },
  [FIND_RESERVATION_FAILURE]({ commit, rootState }) {
    goToPath(rootState, '/');
    commit(LOADING_END);
  },
  [FIND_RESERVATION_SUCCESSFUL]({ dispatch, commit, rootState }, reservation) {
    commit(LOADING_END);

    if (reservation.visible) {
      dispatch(CHANGE_MONTH, { date: moment(reservation.startDate).toDate() });
      dispatch(SELECT_RESERVATION, reservation.id);
    } else {
      const nonVisibleStates = {
        expired: 'expiró', discarded: 'fue eliminada', removed: 'fue eliminada', rescheduled: 'fue reagendada',
      };
      dispatch(OPEN_MODAL, {
        header: `La reserva ${nonVisibleStates[reservation.state]}`,
        body: 'Si quieres saber información sobre esta reserva, escribe a reservas@movistararena.cl',
        button: 'Entendido',
      });
      goToPath(rootState, '/');
    }
  },
  [FETCH_HOLIDAYS_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
  [FETCH_RESERVATIONS_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
  [FETCH_RESCHEDULED_RESERVATIONS_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
  [FETCH_INACTIVE_RESERVATIONS_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
  [FETCH_DUELS]({ commit, dispatch }) {
    commit(LOADING_START);
    duelsApi.get()
      .then(duels => dispatch(FETCH_DUELS_SUCCESSFUL, duels))
      .catch(err => dispatch(FETCH_DUELS_FAILURE, err));
  },
  [FETCH_DUELS_SUCCESSFUL]({ state, commit, dispatch, getters }, duels) {
    commit(LOADING_END);
    commit(SET_DUELS, duels);
    if (getters.duelsAsChallenged.length > 0 && !state.duelsModalShown) {
      dispatch(OPEN_MODAL, {
        header: 'Te están desafiando',
        body: 'Por favor, responde los desafíos que tienes pendientes.',
        button: 'Entendido',
      });
      commit(SET_DUELS_MODAL_SHOWN, true);
    }
  },
  [FETCH_DUELS_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
  [FETCH_CONFIRMATION_ATTEMPTS]({ state, commit, dispatch }) {
    commit(LOADING_START);
    confirmationAttemptsApi.get(state.calendarDate)
      .then(attempts => dispatch(FETCH_CONFIRMATION_ATTEMPTS_SUCCESSFUL, attempts))
      .catch(err => dispatch(FETCH_CONFIRMATION_ATTEMPTS_FAILURE, err));
  },
  [FETCH_CONFIRMATION_ATTEMPTS_SUCCESSFUL]({ commit }, attempts) {
    commit(LOADING_END);
    commit(SET_CONFIRMATION_ATTEMPTS, attempts);
  },
  [FETCH_CONFIRMATION_ATTEMPTS_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
  [START_CONFIRMATION_JOURNEY]({ commit, dispatch }, { reservation, tentativeRescheduledReservationId, options }) {
    commit(LOADING_START);
    reservationApi.startConfirmationJourney(reservation.id, tentativeRescheduledReservationId)
      .then(res => dispatch(START_CONFIRMATION_JOURNEY_SUCCESSFUL, { reservation: res, options }))
      .catch(err => dispatch(START_CONFIRMATION_JOURNEY_FAILURE, err));
  },
  [START_CONFIRMATION_JOURNEY_SUCCESSFUL]({ dispatch }, { options }) {
    dispatch(FETCH_RESERVATIONS);
    dispatch(FETCH_CONFIRMATION_ATTEMPTS);
    if (options.modal === 'confirmation_journey_modal') {
      dispatch(OPEN_MODAL, {
        header: 'Se ha registrado tu intención de confirmar',
        body: 'Si hay reservas con mejor prioridad, ' +
          'se generarán automáticamente desafíos para que esta reserva pueda entrar en un proceso de confirmación.',
        button: 'Entendido',
      });
    } else if (options.modal === 'confirmation_process_modal') {
      dispatch(OPEN_MODAL, {
        header: 'Estás en proceso de confirmación',
        body: 'Escribe a reservas@movistararena.cl para continuar con el proceso.',
        button: 'Entendido',
      });
    }
  },
  [START_CONFIRMATION_JOURNEY_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
  [RESCHEDULE_RESERVATION]({ commit, dispatch }, reservation) {
    commit(LOADING_START);
    rescheduleAttemptsApi.create(reservation)
      .then(() => dispatch(RESCHEDULE_RESERVATION_SUCCESSFUL))
      .catch(err => dispatch(RESCHEDULE_RESERVATION_FAILURE, err));
  },
  [RESCHEDULE_RESERVATION_SUCCESSFUL]({ dispatch }) {
    dispatch(FETCH_RESERVATIONS);
    dispatch(FETCH_RESCHEDULED_RESERVATIONS);
  },
  [RESCHEDULE_RESERVATION_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
  [REMOVE_RESERVATION]({ commit, dispatch }, reservation) {
    commit(LOADING_START);
    reservationApi.removeReservation(reservation.id)
      .then(() => dispatch(REMOVE_RESERVATION_SUCCESSFUL, reservation.id))
      .catch(err => dispatch(REMOVE_RESERVATION_FAILURE, err));
  },
  [REMOVE_RESERVATION_SUCCESSFUL]({ commit, dispatch }) {
    commit(SET_SELECTED_RESERVATION, null);
    dispatch(FETCH_RESERVATIONS);
  },
  [REMOVE_RESERVATION_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
  [MARK_RESERVATION_AS_ZOMBIE]({ commit, dispatch }, reservation) {
    commit(LOADING_START);
    reservationApi.transformToZombie(reservation.id)
      .then(() => dispatch(MARK_RESERVATION_AS_ZOMBIE_SUCCESSFUL, reservation.id))
      .catch(err => dispatch(MARK_RESERVATION_AS_ZOMBIE_FAILURE, err));
  },
  [MARK_RESERVATION_AS_ZOMBIE_SUCCESSFUL]({ commit }) {
    commit(LOADING_END);
  },
  [MARK_RESERVATION_AS_ZOMBIE_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
  [SELECT_RESERVATION]({ commit, dispatch, rootState }, reservationId) {
    commit(SET_SELECTED_RESERVATION, reservationId);
    commit(SET_CREATING_RESERVATION, false);
    dispatch(RESET_SELECTED_DATES);
    goToPath(rootState, `/reservation/${reservationId}`);
  },
  [UNSELECT_RESERVATION]({ commit, state, rootState }) {
    if (state.selectedReservationId) {
      commit(SET_SELECTED_RESERVATION, null);
      goToPath(rootState, '/');
    }
  },
  [SHOW_INACTIVE_RESERVATIONS]({ commit, state, rootState }, date) {
    commit(SET_START_DATE, date);
    commit(SET_END_DATE, date);
    goToPath(rootState, `/inactives/${state.startDate}`);
  },
  [CLOSE_INACTIVE_RESERVATIONS]({ rootState }) {
    goToPath(rootState, '/');
  },
  [START_CREATING_RESERVATION]({ commit, rootState }) {
    commit(SET_CREATING_RESERVATION, true);
    commit(SET_SELECTED_RESERVATION, null);
    goToPath(rootState, '/new');
  },
  [STOP_CREATING_RESERVATION]({ commit, dispatch, rootState }) {
    commit(SET_CREATING_RESERVATION, false);
    dispatch(RESET_SELECTED_DATES);
    commit(SET_START_DATE, null);
    commit(SET_END_DATE, null);
    commit(SET_PIVOT_DATE, null);
    goToPath(rootState, '/');
  },
  [CREATE_RESERVATION]({ commit, dispatch }, reservation) {
    commit(LOADING_START);
    dispatch(RESET_SELECTED_DATES);

    return reservationApi.createReservation(reservation)
      .then((newReservation) => {
        dispatch(CREATE_RESERVATION_SUCCESSFUL, newReservation);
      })
      .catch((error) => dispatch(CREATE_RESERVATION_FAILURE, error.response.data.errors));
  },
  [CREATE_RESERVATION_FAILURE]({ commit, state, dispatch }, errors) {
    commit(SET_RESERVATION_ERRORS, [...state.errors, ...errors]);
    dispatch(OPEN_MODAL, {
      header: 'Error al crear reserva',
      body: sentence(state.errors),
      button: 'Entendido',
    });
    commit(LOADING_END);
  },
  [REMOVE_RESERVATION_ERRORS]({ commit }) {
    commit(SET_RESERVATION_ERRORS, []);
  },
  [CREATE_RESERVATION_SUCCESSFUL]({ commit, dispatch, rootState }, reservation) {
    goToPath(rootState, '/');
    dispatch(OPEN_MODAL, {
      header: 'Reserva creada',
      body: 'Tu reserva ha sido creada con éxito. Te llegará un correo con la información detallada de tu reserva.',
      button: 'OK',
    });
    commit(ADD_RESERVATION, reservation);
    dispatch(STOP_CREATING_RESERVATION);
    commit(SET_SELECTED_RESERVATION, reservation.id);
    commit(LOADING_END);
  },
  [REMOVE_RESERVATION_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
  [RESERVATION_EVENT_RECEIVED]({ state, dispatch }, data) {
    const endOfMonth = moment(state.calendarDate).endOf('month').toDate();
    const afterMonthStart =
      state.calendarDate.getTime() <= new Date(data.from).getTime() ||
      state.calendarDate.getTime() <= new Date(data.to).getTime();
    const beforeMonthEnd =
      endOfMonth.getTime() >= new Date(data.from).getTime() ||
      endOfMonth.getTime() >= new Date(data.to).getTime();

    dispatch(FETCH_DUELS);
    if (afterMonthStart && beforeMonthEnd) {
      dispatch(FETCH_RESERVATIONS);
      dispatch(FETCH_CONFIRMATION_ATTEMPTS);
      dispatch(FETCH_INACTIVE_RESERVATIONS);
      dispatch(FETCH_RESCHEDULED_RESERVATIONS);
    }
  },
  [SELECT_CURRENT_PRODUCER]({ commit, dispatch }, producerId) {
    commit(SET_CURRENT_PRODUCER_ID, producerId);
    commit(SET_IS_ADMIN, false);
    commit(LOADING_END);
    dispatch(FETCH_TRANSFER_ATTEMPTS);
  },
  [UNSELECT_CURRENT_PRODUCER]({ commit }) {
    commit(SET_CURRENT_PRODUCER_ID, null);
    commit(SET_IS_ADMIN, true);
    commit(LOADING_END);
  },
  [MOUSE_ENTER_DATE]({ commit, state }, date) {
    if (state.draggingReservation) {
      const duration = state.pivotDate <= date ? date - state.startDate + 1 : state.endDate - date + 1;
      if (state.pivotDate === date) {
        commit(SET_START_DATE, date);
        commit(SET_END_DATE, date);
      } else if (duration <= MAX_RESERVATION_DURATION) {
        commit(state.pivotDate <= date ? SET_END_DATE : SET_START_DATE, date);
      }
    }
  },
  [MOUSE_MOVE_DATE]({ commit, state }, date) {
    if (date !== state.mouseOnDate) {
      commit(SET_MOUSE_ON_DATE, date);
    }
    if (state.mouseDown && !state.draggingReservation) {
      commit(SET_DRAGGING_RESERVATION, true);
      commit(SET_START_DATE, date);
      commit(SET_END_DATE, date);
      commit(SET_PIVOT_DATE, date);
    }
  },
  [MOUSE_DOWN_DATE]({ commit }, date) {
    commit(SET_MOUSE_DOWN, true);
    commit(SET_START_DATE, date);
    commit(SET_END_DATE, date);
  },
  [MOUSE_UP_DATE]({ state, commit, dispatch, getters }) {
    if (getters.selectionHasConfirmedReservations) {
      dispatch(STOP_CREATING_RESERVATION);
      dispatch(OPEN_MODAL, {
        header: 'Fechas no válidas',
        body: 'Las fechas que seleccionaste tienen una reserva confirmada, ' +
          'por lo que no es posible agregar una nueva reserva.',
        button: 'Entendido',
      });
    } else if (state.mouseDown) {
      dispatch(START_CREATING_RESERVATION);
    }
    commit(SET_DRAGGING_RESERVATION, false);
    commit(SET_MOUSE_DOWN, false);
  },
  [ADD_RESERVATION_ERROR]({ state, commit }, error) {
    commit(SET_RESERVATION_ERRORS, [...state.errors, error]);
  },
  [CLOSE_MODAL]({ commit }) {
    commit(SHOW_MODAL, false);
    commit(SHOW_FORM_MODAL, false);
    commit(SET_MODAL_DATA, {});
    commit(SET_RESERVATION_ERRORS, []);
  },
  [OPEN_MODAL]({ commit }, modalData) {
    commit(SET_MODAL_DATA, modalData);
    commit(SHOW_MODAL, true);
  },
  [CREATE_ARTIST]({ commit, dispatch }, artist) {
    commit(LOADING_START);
    artistApi.create(artist)
      .then((newArtist) => {
        dispatch(CREATE_ARTIST_SUCCESSFUL, newArtist);
      })
      .catch((error) => {
        dispatch(CREATE_ARTIST_FAILURE, `${error}`);
      });
  },
  [GET_ARTISTS]({ commit, dispatch }) {
    commit(LOADING_START);
    artistApi.get()
      .then((artists) => {
        dispatch(GET_ARTISTS_SUCCCESSFUL, artists);
      })
      .catch((error) => {
        dispatch(GET_ARTISTS_FAILURE, `${error.response.status}`);
      });
  },
  [GET_ARTISTS_SUCCCESSFUL]({ commit }, artists) {
    commit(SET_ARTISTS, artists);
    commit(LOADING_END);
  },
  [GET_ARTISTS_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
  [CREATE_ARTIST_SUCCESSFUL]({ commit }, artist) {
    commit(LOADING_END);
  },
  [CREATE_ARTIST_FAILURE]({ commit }, error) {
    commit(LOADING_END);
  },
  [RESET_SELECTED_DATES]({ commit }) {
    commit(SET_START_DATE, null);
    commit(SET_END_DATE, null);
    commit(SET_PIVOT_DATE, null);
  },
  [GET_PRODUCERS]({ commit, dispatch }) {
    commit(LOADING_START);
    producersApi.get()
      .then((producers) => {
        dispatch(GET_PRODUCERS_SUCCESSFUL, producers);
      })
      .catch((error) => {
        dispatch(GET_PRODUCERS_FAILURE, `${error.response.status}`);
      });
  },
  [GET_PRODUCERS_SUCCESSFUL]({ commit }, producers) {
    commit(SET_PRODUCERS, producers);
    commit(LOADING_END);
  },
  [GET_PRODUCERS_FAILURE]({ commit }, error) {
    commit(LOADING_END);
  },
  [GET_PRODUCER_PERMISSIONS]({ commit, dispatch }) {
    commit(LOADING_START);
    producersApi.permissions()
      .then((permissions) => {
        dispatch(GET_PRODUCER_PERMISSIONS_SUCCESSFUL, permissions);
      })
      .catch((error) => {
        dispatch(GET_PRODUCER_PERMISSIONS_FAILURE, `${error.response.status}`);
      });
  },
  [GET_PRODUCER_PERMISSIONS_SUCCESSFUL]({ commit, dispatch }, permissions) {
    commit(LOADING_END);
    if (permissions.isAdmin) {
      dispatch(UNSELECT_CURRENT_PRODUCER);
    } else {
      dispatch(SELECT_CURRENT_PRODUCER, permissions.producerId);
    }
  },
  [GET_PRODUCER_PERMISSIONS_FAILURE]({ commit }, error) {
    commit(LOADING_END);
  },
  [RENEW_RESERVATION]({ commit, dispatch }, reservation) {
    commit(LOADING_START);
    reservationApi
      .renewReservation(reservation.id)
      .then(res => dispatch(RENEW_RESERVATION_SUCCESSFUL, res))
      .catch(err => dispatch(RENEW_RESERVATION_FAILURE, err));
  },
  [RENEW_RESERVATION_FAILURE]({ commit, dispatch, state }, errors) {
    commit(LOADING_END);
    commit(SET_RESERVATION_ERRORS, [...state.errors, ...errors]);
    dispatch(OPEN_MODAL, {
      header: 'Error al renovar reserva',
      body: sentence(state.errors),
      button: 'Entendido',
    });
  },
  [RENEW_RESERVATION_SUCCESSFUL]({ commit }) {
    commit(LOADING_END);
  },
  [TOGGLE_PENDINGS]({ rootState }) {
    if (rootState.route.path === '/pendings') {
      goToPath(rootState, '/');
    } else {
      goToPath(rootState, '/pendings');
    }
  },
  [TRANSFER_RESERVATION]({ commit, dispatch }, params) {
    commit(LOADING_START);
    transferAttemptsApi.create(params)
      .then(() => dispatch(TRANSFER_RESERVATION_SUCCESSFUL))
      .catch(err => dispatch(TRANSFER_RESERVATION_FAILURE, err));
  },
  [TRANSFER_RESERVATION_SUCCESSFUL]({ dispatch }) {
    dispatch(FETCH_RESERVATIONS);
  },
  [TRANSFER_RESERVATION_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
  [FETCH_TRANSFER_ATTEMPTS]({ state, commit, dispatch }) {
    commit(LOADING_START);
    transferAttemptsApi.get(state.currentProducerId)
      .then(attempts => dispatch(FETCH_TRANSFER_ATTEMPTS_SUCCESSFUL, attempts))
      .catch(err => dispatch(FETCH_TRANSFER_ATTEMPTS_FAILURE, err));
  },
  [FETCH_TRANSFER_ATTEMPTS_SUCCESSFUL]({ commit }, attempts) {
    commit(LOADING_END);
    commit(SET_TRANSFER_ATTEMPTS, attempts);
  },
  [FETCH_TRANSFER_ATTEMPTS_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
  [REDIRECT_TO_TRANSFER_ATTEMPT]({ state, rootState }, id) {
    if (state.transferAttempts.find(res => res.id === id)) {
      goToPath(rootState, `/transfer/${id}`);
    }
  },
  [REDIRECT_TO_ROOT]({ rootState }) {
    goToPath(rootState, '/');
  },
  [UPDATE_TRANSFER_ATTEMPT]({ commit, dispatch }, params) {
    commit(LOADING_START);
    transferAttemptsApi.update(params)
      .then(() => dispatch(UPDATE_TRANSFER_ATTEMPT_SUCCESSFUL))
      .catch(err => dispatch(UPDATE_TRANSFER_ATTEMPT_FAILURE, err));
  },
  [UPDATE_TRANSFER_ATTEMPT_SUCCESSFUL]({ dispatch }) {
    dispatch(FETCH_TRANSFER_ATTEMPTS);
    dispatch(REDIRECT_TO_ROOT);
  },
  [UPDATE_TRANSFER_ATTEMPT_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
  [FETCH_RATE]({ commit }, params) {
    commit(LOADING_START);
    rates.get(params.arenaTypeId, params.date)
      .then(rate => {
        commit(SET_CURRENT_RATE, rate);
        commit(LOADING_END);
      })
      .catch(err => commit(LOADING_END, err));
  },
  [CANCEL_RESERVATION]({ commit, dispatch }, params) {
    commit(LOADING_START);
    cancelAttemptsApi.create(params)
      .then(() => dispatch(CANCEL_RESERVATION_SUCCESSFUL))
      .catch(err => dispatch(CANCEL_RESERVATION_FAILURE, err));
  },
  [CANCEL_RESERVATION_SUCCESSFUL]({ dispatch }) {
    dispatch(FETCH_RESERVATIONS);
  },
  [CANCEL_RESERVATION_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
  [FETCH_CURRENT_CANCEL_DATE_THRESHOLD]({ dispatch, commit }, params) {
    commit(LOADING_START);
    cancelAttemptsApi.dateThreshold(params)
      .then((res) => {
        if (res.dateThreshold) {
          commit(SET_CURRENT_CANCEL_DATE_THRESHOLD, res.dateThreshold);
        }
        commit(LOADING_END);
      })
      .catch(err => dispatch(FETCH_CURRENT_CANCEL_DATE_THRESHOLD_FAILURE, err));
  },
  [FETCH_CURRENT_CANCEL_DATE_THRESHOLD_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
};
