export const LOADING_START = 'LOADING_START';
export const LOADING_END = 'LOADING_END';

export const SET_RESERVATIONS = 'SET_RESERVATIONS';
export const SET_RESCHEDULED_RESERVATIONS = 'SET_RESCHEDULED_RESERVATIONS';
export const SET_INACTIVE_RESERVATIONS = 'SET_INACTIVE_RESERVATIONS';
export const UPDATE_RESERVATION = 'UPDATE_RESERVATION';
export const DELETE_RESERVATION = 'DELETE_RESERVATION';
export const ADD_RESERVATION = 'ADD_RESERVATION';
export const SET_SELECTED_RESERVATION = 'SET_SELECTED_RESERVATION';

export const SET_HOLIDAYS = 'SET_HOLIDAYS';

export const SET_CREATING_RESERVATION = 'SET_CREATING_RESERVATION';

export const SET_CALENDAR_DATE = 'CHANGE_CALENDAR_DATE';

export const SET_CURRENT_PRODUCER_ID = 'SET_CURRENT_PRODUCER_ID';
export const SET_IS_ADMIN = 'SET_IS_ADMIN';

export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_PIVOT_DATE = 'SET_PIVOT_DATE';
export const SET_DRAGGING_RESERVATION = 'SET_DRAGGING_RESERVATION';
export const SET_MOUSE_DOWN = 'SET_MOUSE_DOWN';
export const SET_MOUSE_ON_DATE = 'SET_MOUSE_ON_DATE';

export const SET_PRODUCERS = 'SET_PRODUCERS';
export const SET_ARTISTS = 'SET_ARTISTS';
export const SET_RESERVATION_ERRORS = 'SET_RESERVATION_ERRORS';

export const SHOW_MODAL = 'SHOW_MODAL';
export const SHOW_FORM_MODAL = 'SHOW_FORM_MODAL';
export const SET_MODAL_DATA = 'SET_MODAL_DATA';
export const SET_CONFIRMATION_ATTEMPTS = 'SET_CONFIRMATION_ATTEMPTS';
export const SET_DUELS = 'SET_DUELS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_DUELS_MODAL_SHOWN = 'SET_DUELS_MODAL_SHOWN';
export const SET_TRANSFER_ATTEMPTS = 'SET_TRANSFER_ATTEMPTS';
export const SET_ARENA_TYPES = 'SET_ARENA_TYPES';

export const SET_CURRENT_RATE = 'SET_CURRENT_RATE';

export const SET_CURRENT_CANCEL_DATE_THRESHOLD = 'SET_CURRENT_CANCEL_DATE_THRESHOLD';
